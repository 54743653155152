import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SectionHeader from '../../components/SectionHeader'
import SectionTextTwoColumns from '../../components/SectionTextTwoColumns'
import Layout from '../../layout/Layout'
import IconKalkulator from '../../images/icons/kalkulator.svg'
import IconRodzaj from '../../images/icons/rodzaj.svg'
import IconUstawienia from '../../images/icons/ustawienia.svg'

export default function Home() {
  const { headerBg } = useStaticQuery(
    graphql`
      query {
        headerBg: file(
          relativePath: { eq: "nasze-uslugi/transport-krajowy.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  return (
    <Layout
      metaTitle="Transport krajowy"
      metaDescription="Chcesz zlecić krajowy transport drogowy i poszukujesz zaufanego podwykonawcy, któremu możesz powierzyć zadanie z gwarancją profesjonalnej realizacji w konkurencyjnej cenie?"
      slug="/uslugi/transport-krajowy"
    >
      <SectionHeader
        smallTitle="Usługi"
        title={
          <>
            Transport <br />
            krajowy
          </>
        }
        titleBorder="white"
        lead="Chcesz zlecić krajowy transport drogowy i poszukujesz zaufanego podwykonawcy, któremu możesz powierzyć zadanie z gwarancją profesjonalnej realizacji w konkurencyjnej cenie? "
        content="Wobec tego mamy propozycję spełniającą Twoje wymagania. Działamy jako firma przewozowa oferująca transport drogowy na terenie całej Polski. Z przyjemnością zostaniemy Twoim partnerem biznesowym i pomożemy w rozwoju poprzez zapewnienie pełnej obsługi przewozowej. Sprawdź, jak działamy i dlaczego możesz nam zaufać."
        buttonType="bordered"
        scroll="#czytaj-wiecej"
        halfBg
        bgImage={getImage(headerBg)}
      />
      <SectionTextTwoColumns
        id="czytaj-wiecej"
        smallTitle="Transport krajowy"
        title={
          <>
            Zaufany partner
            <br />
            w krajowym
            <br />
            transporcie
            <br />
            drogowym
          </>
        }
        lead={
          <>
            Współpracujemy z Klientami z&nbsp;każdego zakątka Polski. Zapewniamy
            szybki i&nbsp;bezpieczny przewóz nawet na najdalszych dystansach,
            np. ze Szczecina do Rzeszowa czy z&nbsp;Zakopanego do Władysławowa.
          </>
        }
      >
        <p>
          Specjalizujemy się w krajowym transporcie drogowym ładunków każdego
          typu – możesz nam powierzyć przewóz, zarówno kilku niewielkich
          przedmiotów, jak i zlecić hurtowe dostawy towarów, które wymagają
          zaangażowania kilku ciężarówek. W każdym przypadku masz pewność
          terminowości i bezpieczeństwa dostaw. Ubezpieczamy zawartość pojazdów
          i współpracujemy wyłącznie z doświadczonymi, wysoko wykwalifikowanymi
          kierowcami, którzy dysponują nowoczesnym i pewnym taborem. Pozwala nam
          to na zagwarantowanie najwyższego poziomu bezpieczeństwa współpracy.
        </p>
        <p className="lead">Dostarczymy Twoje towary niezależnie od:</p>
        <ul className="icons-list">
          <li>
            <IconKalkulator />
            <p className="lead">Ilości</p>
          </li>
          <li>
            <IconRodzaj />
            <p className="lead">Rodzaju</p>
          </li>
          <li>
            <IconUstawienia />
            <p className="lead">Specyfiki</p>
          </li>
        </ul>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport krajowy"
        title={
          <>
            Transport drogowy
            <br />
            każdego rodzaju
          </>
        }
        lead={
          <>
            Nasze zadanie polega na sprawnej organizacji krajowego transportu
            drogowego, dlatego działamy elastycznie i&nbsp;z&nbsp;indywidualnym
            podejściem do każdego zlecenia. Dokładnie analizujemy trasy
            i&nbsp;wybieramy te, które gwarantują najefektywniejszą dostawę
            towarów.
          </>
        }
      >
        <p className="lead">
          Rozbudowana sieć partnerów w&nbsp;całej Polsce umożliwia nam
          dostarczanie nawet najbardziej nietypowych, ponadgabarytowych
          ładunków, jak np.:
        </p>
        <ul>
          <li>
            maszyny i urządzenia produkcyjne, budowlane, wydobywcze,
            transportowe, rolnicze itp.
          </li>
          <li>duże elementy konstrukcyjne</li>
          <li>pojazdy</li>
          <li>zbiorniki</li>
          <li>konstrukcje, np. hale</li>
          <li>podzespoły infrastruktury drogowej.</li>
        </ul>
        <p>
          Na każdym etapie dbamy o jakość wykonywanych usług i&nbsp;Twoje
          zadowolenie ze współpracy, dlatego dobieramy rozwiązania ograniczające
          koszty do minimum i&nbsp;jednocześnie zapewniające wysoką efektywność
          realizacji. W&nbsp;połączeniu z&nbsp;doborem sprawdzonych
          i&nbsp;rzetelnych podwykonawców przekłada się to na satysfakcjonującą
          współpracę, która przynosi wymierne korzyści Tobie i&nbsp;Twojej
          firmie.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport krajowy"
        title={
          <>
            Pewne wsparcie <br />
            transportowe <br />w biznesie
          </>
        }
        lead={
          <>
            Zdajemy sobie sprawę z tego, jak ważna jest obsługa Klienta
            i&nbsp;zagwarantowanie Ci stałego dostępu do informacji na temat
            Twoich ładunków, dlatego stawiamy na pełną dyspozycyjność
            i&nbsp;szybki kontakt z&nbsp;dedykowanym opiekunem.
          </>
        }
      >
        <p>
          Dzięki temu w dowolnym momencie możesz uzyskać odpowiedź na temat
          lokalizacji pojazdów oraz przewidywanego czasu dotarcia do celu. Daje
          Ci to pełną kontrolę nad Twoimi towarami i&nbsp;przekłada się na
          wysoki poziom bezpieczeństwa współpracy. Dbamy o&nbsp;nie także
          poprzez pomoc przy zakupie ubezpieczeń CARGO, które chronią Cię przed
          skutkami finansowymi ewentualnego zniszczenia czy utraty towaru
          z&nbsp;powodów losowych.
        </p>
        <p>
          Zależy nam na zapewnieniu pełnego wsparcia Klientów, dlatego oferowany
          krajowy transport drogowy ma kompleksowy charakter - dostarczenie
          towarów z&nbsp;punktu A&nbsp;do&nbsp;B. Mamy świadomość jak ważnym
          ogniwem w&nbsp;działalności naszych Klientów jest transport, dlatego
          chcemy w&nbsp;jak największym stopniu go ułatwić i&nbsp;sprawić, żeby
          przebiegał tak wydajnie, jak to tylko jest możliwe.
        </p>
        <p>
          Naszym zadaniem jest zorganizowanie drogowego przewozu krajowego
          w&nbsp;taki sposób, żeby przebiegał efektywnie, bezpiecznie
          i&nbsp;zgodnie z&nbsp;przepisami. Bierzemy pod uwagę wszystkie
          czynniki, które mają wpływ na sprawną realizację zlecenia. Dobieramy
          odpowiednie metody i&nbsp;środki do danego towaru, a&nbsp;ponieważ
          współpracujemy z&nbsp;partnerami w&nbsp;całej Polsce, mamy dostęp do
          pojazdów wszelkiego typu. Dzięki temu możemy świadczyć elastyczne
          usługi, wychodzące naprzeciw Twoim potrzebom, niezależnie od ich
          charakteru.
        </p>
      </SectionTextTwoColumns>
      <SectionTextTwoColumns
        smallTitle="Transport krajowy"
        title={
          <>
            Sprawna organizacja <br />
            transportu krajowego <br />
            na każdym etapie
          </>
        }
        lead={
          <>
            Współpracujemy z firmami z&nbsp;wszystkich branż. Realizujemy,
            zarówno pojedyncze zlecenia, jak i&nbsp;regularne dostawy na rzecz
            stałych Klientów.
          </>
        }
      >
        <p>
          Oszczędzamy Twój czas i&nbsp;bierzemy na siebie wszystkie obowiązki,
          z&nbsp;którymi wiąże się krajowy transport drogowy, a&nbsp;Ty możesz
          się zająć innymi sprawami. Zamiast przeznaczać cenne godziny
          i&nbsp;zasoby na organizację przewozu, zlecasz go naszemu zespołowi,
          a&nbsp;my go zrealizujemy profesjonalnie, szybko, bezpiecznie
          i&nbsp;w&nbsp;korzystnych cenach.
        </p>
        <p>
          Stawiamy na uczciwe i&nbsp;jasne warunki współpracy, która przynosi
          korzyści obu stronom. Ty otrzymujesz pełne wsparcie w&nbsp;transporcie
          drogowym, a&nbsp;nasza firma może się rozwijać i&nbsp;zdobywać
          doświadczenie, dzięki Twojemu zaufaniu. Gwarantujemy minimum
          formalności i&nbsp;pełną transparentność świadczonych usług.
          Zapraszamy do zapoznania się z&nbsp;ofertą i&nbsp;skontaktowania się
          w&nbsp;celu nawiązania współpracy każdego, kto poszukuje sprawdzonego
          i&nbsp;pewnego partnera w&nbsp;krajowym transporcie drogowym!
        </p>
      </SectionTextTwoColumns>
    </Layout>
  )
}
